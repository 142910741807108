<template>
  <div class="w">
    <img width="100%" src="https://oss.culturalcloud.net/furong/202305/16115428l2vj.jpg" alt="">
    <div class="layout-head">
      <div class="head-p">视频资源</div>
    </div>
    <!-- {{ list }} -->
    <div class="list clearfix">
      <div class="item" v-for="item in list" :key="item.id" @click="$router.push({ path: '/video/' + item.id })">
          <img :src="item.titleImg" alt="">
          <div class="item-dl">
            <div class="item-title">{{ item.title }}</div>
            <!-- <div class="item-name">{{ item.title }}</div> -->
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideoList } from "@/api";
export default {
  name: 'hall',
  data() {
    return {
      list: [],
      queryParams: {
        pageNum: 1,
        pageSize: 100,
        type: '亮旗大讲堂'
      },
    }
  },
  created() {
    getVideoList(this.queryParams).then(res => {
      if (res.code === 200) {
        this.list = res.rows
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.layout-head {
  margin: 30px 0;
  height: 37px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: url('https://www.ttcdw.cn/static/djss/images/layout_head_icon.png') no-repeat;
    top: 0;
    left: 0;
    width: 37px;
    height: 37px;
  }

  .head-p {
    padding-left: 13px;
    position: relative;
    float: left;
    height: 37px;
    font-size: 28px;
    color: #200F0E;
    line-height: 37px;
  }
}

.list {
  // display: none;
  // width: 1220px;
  // height: 100%;

  .item {
    float: left;
    width: 270px;
    height: 230px;
    border: 1px solid #E6DCDC;
    box-sizing: border-box;
    margin-right: 34px;
    margin-bottom: 36px;

    transition: all .5s ease;
    &:hover {
      // transition: 1s;
			/* 过度时间 */
			transform: scale(1.1);
			/* 变形一比一放大 */

    }
    img {
      width: 268px;
      height: 160px;

    }

    .item-dl {
      width: 100%;
      background: #fff;
      // height: 101px;
      // box-sizing: border-box;
      padding: 18px 25px 0 19px;

      .item-title {
        max-height: 38px;
        font-size: 16px;
        font-weight: bold;
        color: #342525;
        line-height: 19px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .item-name {
        margin-top: 10px;
        height: 19px;
        font-size: 14px;
        color: #7B6C6C;
        line-height: 19px;
      }
    }
  }
}</style>